import React from "react"
import { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Speed from "../components/Speed"
// import fs from 'fs'
// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Logo from "../images/seelay-art.inline.svg"
// Styles
import "./index.scss"
import "../components/footer.scss"

const IndexPage = ({ data }) => {
  const projects = data.projects.edges
  // const exhibitions = data.exhibitions.edges
  const indexPage = data.indexPage.edges[0].node

  async function shareEvent(url, title, text) {
    try {
      await navigator.share({
        url: url,
        title: title,
        text: text,
      })
      console.log("Successfully shared event")
    } catch (err) {
      window.open(
        `https://wa.me/?text=${encodeURI(`${title} - ${text} | ${url} `)}`
      )
      console.log(`Error: ${err}`)
    }
  }

  useEffect(() => {
    window.addEventListener(
      "contextmenu",
      function(e) {
        // do something here...
        e.preventDefault()
      },
      false
    )
  }, [])

  return (
    <Layout>
      <SEO title="Seelay Art" />
      {/* <div className="hero">
      <div className="hero__image">
        <Img fluid={data.heroImage.childImageSharp.fluid} />
      </div>
    </div> */}
      <Container>
        <div id="hero" className="intro">
          {/* <Img
            className="intro__image"
            fluid={indexPage.frontmatter.introImage.childImageSharp.fluid}
          /> */}
          <div className="intro__quote">
            <div className="intro__about">
              <div
                dangerouslySetInnerHTML={{
                  __html: indexPage.frontmatter.intro,
                }}
              ></div>
              {/* <Link to='/about' class="intro__link">Continue reading</Link> */}
            </div>
          </div>
        </div>
      </Container>
      <Container id="artworks">
        {/* <h2 className="container__headline">Artworks</h2> */}

        <h3 className="gallery__type">Vector Illustration</h3>
        <div className="projects">
          {projects
            .filter(({ node }) => node.frontmatter.type === "Vector")
            .map(({ node }) => {
              return (
                <Link to={node.fields.slug} key={node.id} className="project">
                  <Img
                    id="amn"
                    className="project__image"
                    fluid={{
                      ...node.frontmatter.coverImage.childImageSharp.fluid,
                      sizes:
                        "(max-width: 500px) 40vw, (max-width: 500px) 50vw, (max-width: 500px) 60vw", // Responsive breakpoints
                    }}
                  />
                  <div className="project__info-container">
                    <div className="project__info">
                      <h2 className="project__headline">
                        {node.frontmatter.title}
                      </h2>
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
        <h3 className="gallery__type">Sketches</h3>
        <div className="projects">
          {projects
            .filter(({ node }) => node.frontmatter.type === "Sketches")
            .map(({ node }) => {
              return (
                <Link to={node.fields.slug} key={node.id} className="project">
                  <Img
                    className="project__image"
                    fluid={node.frontmatter.coverImage.childImageSharp.fluid}
                  />
                  <div className="project__info-container">
                    <div className="project__info">
                      <h2 className="project__headline">
                        {node.frontmatter.title}
                      </h2>
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
        <h3 className="gallery__type">Digital Art</h3>
        <div className="projects">
          {projects
            .filter(({ node }) => node.frontmatter.type === "Concepts")
            .map(({ node }) => {
              return (
                <Link to={node.fields.slug} key={node.id} className="project">
                  <Img
                    className="project__image"
                    fluid={node.frontmatter.coverImage.childImageSharp.fluid}
                  />
                  <div className="project__info-container">
                    <div className="project__info">
                      <h2 className="project__headline">
                        {node.frontmatter.title}
                      </h2>
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
        {/* <h3 className="gallery__type">Pastell</h3> */}
        {/* <div className="projects">
        {projects.filter(({ node }) => node.frontmatter.type === 'Pastell').map(({ node }) => {
          return (
            <Link to={node.fields.slug} key={node.id} className="project">
              <Img className="project__image" fluid={node.frontmatter.coverImage.childImageSharp.fluid} />
              <div className="project__info-container">
                <div className="project__info">
                  <h2 className="project__headline">{node.frontmatter.title}</h2>
                </div>
              </div>
            </Link>
          )
        })}
      </div> */}
      </Container>

      <Container id="contact" styleModifier={["dark-theme"]}>
        <h2 className="container__headline container__headline--light">
          Get In Touch
        </h2>
        <div
          className="contact"
          dangerouslySetInnerHTML={{ __html: indexPage.frontmatter.contact }}
        >
          {/* <p className="contact__desc p p--light">Hallo! Für allgemeine Anfragen schick eine Email an <a href="mailto:aman@seelay.in">aman@seelay.in</a>. Wenn du Interesse an einem Kauf der Bilder hast, schicke eine <a href={`mailto:aman@seelay.in?subject=Kaufanfrage - ${new Date().toISOString()}`}>Kaufanfrage</a>.</p> */}
        </div>
      </Container>

      <Container id="footer">
        <div className="speedmobile">
          <Speed />
        </div>
        <footer className="footer">
          <Link to="/#">
            <Logo />
          </Link>
          <div className="speeddesktop">
            <Speed />
          </div>

          <div className="footer__notes">
            <span className="footer__item">© 2024</span>
            <a target="blank" href="https://www.seelay.in">
              <span id="seelay-footer">SEELAY</span>
            </a>
            <Link to="/legal" className="footer__item">
              Legal
            </Link>
          </div>
        </footer>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query indexPage {
    projects: allMarkdownRemark(      
      filter: {fileAbsolutePath: {regex: "/(\/content\/projects)/.*\\.md$/"}}
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC]}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            type
            coverImage {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
              }
            }
          }
        }
      }
    }
    exhibitions: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(\/content\/exhibitions)/.*\\.md$/"}}
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC]}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            dateText
            location
            price
          }
        }
      }
    }
    indexPage: allMarkdownRemark(      
      filter: {fileAbsolutePath: {regex: "/(\/content\/pages\/index)/.*\\.md$/"}}
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC]}
    ) {
      edges {
        node {
          frontmatter {
            intro
            introImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            contact
            coverImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    heroImage: file(relativePath: { eq: "painting-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    myImage: file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage
